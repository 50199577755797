import React from 'react'

import {
    Paper,
    makeStyles,
    Typography,
    CardContent,
    CircularProgress,
    Button
} from '@material-ui/core';

const useStyles = makeStyles({
    titleWithMarginTop: {
        marginTop: 80,
    },
    title: {
    },
    cancelButton: {
        height: 36,
        margin: '15px 0',
        backgroundColor: '#FCA2A2',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#FCA2A2',
        }
    },
    confirmButton: {
        height: 36,
        width: '100%',
        margin: '15px 0',
        backgroundColor: '#94B9FF',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#94B9FF',
        }
    },
    divStyle: {
        padding: '20px 20px 20px 20px',
    },
    centrelizedDivStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    loaderStyle: {
        height: 36,
        padding: '15px 15px 15px 15px',
        justifyContent: 'center',
        display: 'flex',
    },
    rowStyle: {
        display: 'flex',
        justifyContent: 'space-around'
    }

});

export const Wrapper = props => {
    const classes = useStyles();
    return (
        <CardContent>
            <Paper>
                <div className={classes.divStyle}>{props.children}</div>
            </Paper>
        </CardContent>
    );
}

export const Centrelized = props => {
    const classes = useStyles();
    return (
        <div className={classes.centrelizedDivStyle}>{props.children}</div>
    );
}

export const Title = props => {
    const classes = useStyles();
    return (
        <Typography
            variant="h5"
            component="h3"
            align={props.center ? "center" : "left"}
            className={props.marginTop ? classes.titleWithMarginTop : classes.title}>
            {props.children}
        </Typography>
    )
}

export const CancelButton = props => {
    const classes = useStyles();
    return (
        <Button
            {...props}
            variant="contained"
            className={classes.cancelButton}>
            Cancelar
        </Button>
    )
}

export const ConfirmButton = props => {
    const classes = useStyles();
    return (
        <Button
            {...props}
            variant="contained"
            className={classes.confirmButton}>
            Confirmar
        </Button>
    )
}

export const Row = props => {
    const classes = useStyles();
    return (
        <div className={classes.rowStyle} style={{ width: props.width }}>{props.children}</div>
    );
}

export const Loader = props => {
    const classes = useStyles();
    return (
        <div className={classes.loaderStyle}>
            <CircularProgress />
        </div>
    );
}