import React, { useState } from 'react';
import { SimpleForm, TextInput, useNotify, useDataProvider } from 'react-admin';
import { Loader } from '../pageComponents';
import {
    Centrelized,
    Title,
    ConfirmButton,
} from '../../components';

const OperatorManagerLogin = ({ loginCallback }) => {
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const handleLogin = async () => {
        setLoading(true);
        dataProvider.create(`employees/login`, { data: { login, password } })
            .then((response) => {
                setLoading(false);
                loginCallback(response);
            })
            .catch((err) => {
                setLoading(false);
                notify('Usuário ou senha inválidos', 'warning');
            })
    }

    return (
        <div style={{ width: '300px' }}>
            <Centrelized>
                <Title center={true}>Digite um usuário e senha que tenha permissão para esta ação</Title>
                <SimpleForm toolbar={null} variant="outlined">
                    <TextInput
                        fullWidth
                        source="login"
                        label="Usuário"
                        onChange={event => setLogin(event.target.value)}
                    />
                    <TextInput
                        fullWidth
                        source="password"
                        label="Senha"
                        type="password"
                        onChange={event => setPassword(event.target.value)}
                    />
                    {loading ?
                        <Loader /> :
                        <ConfirmButton onClick={handleLogin} />
                    }
                </SimpleForm>
            </Centrelized>
        </div>
    );

}

export default OperatorManagerLogin;