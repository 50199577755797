import React from 'react';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import {
    Grid,
    Card,
    CardMedia,
    CardContent,
    Typography,
    makeStyles,
    CardActionArea,
    Button
} from '@material-ui/core';

const useStyles = makeStyles({
    media: {
        height: 140,
    },
    cash: {
        height: 140,
        width: '100%',
        backgroundColor: '#7FC99A'
    },
    noPhoto: {
        height: 140,
        padding: 0,
        backgroundColor: '#E2E2E2'
    },
    title: {
        paddingBottom: '0.5em',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    redeeemButton: {
        height: 36,
        backgroundColor: '#7FC99A',
        textTransform: 'none',
        margin: '0.5em 0',
        '&:hover': {
            backgroundColor: '#7FC99A',
        }
    },
});

export default ({ product, productSelectedCallback, generateCoupon }) => {
    const classes = useStyles();

    return (
        <Grid key={product.id} xs={12} sm={6} md={4} lg={3} xl={2} item>
            <Card>
                <CardActionArea onClick={() => productSelectedCallback ? productSelectedCallback(product) : null}>
                    {product.isCash ?
                        <LocalAtmIcon
                            className={classes.cash} />
                        :
                        product.imagePath ?
                            <CardMedia
                                image={product.imagePath}
                                className={classes.media}
                            />
                            :
                            <CardContent className={classes.noPhoto} />
                    }
                    <CardContent className={classes.title}>
                        <Typography
                            variant="h6"
                            component="h4"
                            align="center"
                            noWrap={true}
                        >
                            {product.isCash ?
                                `R$${(Math.round(product.name * 100) / 100).toFixed(2)}`
                                :
                                product.name || ""}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            component="h3"
                            align="center"
                            noWrap={true}
                        >
                            {product.isGift ? product.place : `A partir de ${Math.min(...product.productPlaces.map((place) => place.price))} pontos`}
                        </Typography>
                        {product.isGift && (
                            <Button
                                variant="contained"
                                className={classes.redeeemButton}
                                onClick={generateCoupon}
                            >
                                Gerar cupom
                            </Button>
                        )}
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    )
};