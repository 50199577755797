export const CHAIN_ID_KEY = 'chainId';
export const CHAIN_NAME_KEY = 'chainName';
export const PERMISSIONS_KEY = 'permissions';
export const PLACE_ID_KEY = 'placeId';
export const CASHBACK_FACTOR = 'cashbackFactor';
export const USER_ID_KEY = 'userId';

export const PERMISSIONS = {
    ACCESS_RESGATE: 'access-resgate',
    REPRINT_COUPON: 'reprint-coupon',
    PASSWORD_LESS_REDEMPTIOM:'password-less-redemption',
}  