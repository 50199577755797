import React, { Component } from 'react';
import { Admin, Resource } from 'react-admin';
import { ApiProvider, AuthProvider } from './providers';
import { BrowserRouter as Router } from "react-router-dom"
import CustomLayout from './Layout';
import CustomRoutes from './customRoutes'
import ptBrMessages from 'ra-language-pt-br';
import polyglotI18nProvider from 'ra-i18n-polyglot';

const messages = {
  'pt-br': ptBrMessages,
};
const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'pt-br');

class App extends Component {
  render() {
    return (
      <Router>
        <Admin
          title="Módulo Resgate"
          i18nProvider={i18nProvider}
          authProvider={AuthProvider}
          dataProvider={ApiProvider}
          layout={CustomLayout}
          customRoutes={CustomRoutes}
        >
          <Resource name='statement' />
        </Admin>
      </Router>

    );
  }
}

export default App;
