import React from 'react';
import {
    Wrapper,
    Title,
    CancelButton,
    Centrelized
} from '../../components'
import { getChainId } from '../../lib';
import { makeStyles, Button } from '@material-ui/core';
import { SimpleForm, useDataProvider, useNotify } from 'react-admin';
import { useRegisterContext } from '.'

const useStyles = makeStyles({
    centered: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 30,
        flexDirection: 'column',
        width: 250,
    },
    resendButton: {
        height: 36,
        textTransform: 'none',
        backgroundColor: '#b7d5ac',
        '&:hover': {
            backgroundColor: '#b7d5ac',
        }
    },
    resendWrapper: {
        position: 'relative',
        margin: '15px 0',
        width: 250,
    }

});

const ResendCode = () => {
    const { setResendCode, cpf, cellphone, clearFlow, isNewCustomer } = useRegisterContext();
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const handleResend = (mode) => {
        let url = 'forgot-password';
        const data = { cpf };

        if(isNewCustomer) {
            url = 'signup'
            data.sendType = mode;
        } else {
            data.recoveryMode = mode;
        }

        dataProvider.create(`chains/${getChainId()}/customers/${url}`, { data })
            .then(() => {
                notify(`Código enviado.`);
                setResendCode(false);
            })
            .catch(() => {
                notify('Erro ao enviar código!', 'warning');
            })
    }

    const ResendButton = () => (
        <div className={classes.resendWrapper}>
            <Button
                variant="contained"
                className={classes.resendButton}
                onClick={() => handleResend("cellphone")}
                fullWidth
            >
                Apenas SMS
            </Button>
        </div>
    )

    const ResendWhatsAppButton = () => (
        <div className={classes.resendWrapper}>
            <Button
                variant="contained"
                className={classes.resendButton}
                onClick={() => handleResend("whatsapp")}
                fullWidth
            >
                Apenas WhatsApp
            </Button>
        </div>
    )

    return (
        <Wrapper>
            <Centrelized>
                <Title center={true}>Reenvio de Código para o celular com final {cellphone}</Title>
                <div className={classes.centered}>
                    <SimpleForm toolbar={null} variant="outlined" className={classes.centered}>
                        <ResendButton />
                        <ResendWhatsAppButton />
                        <CancelButton fullWidth onClick={clearFlow} />
                    </SimpleForm>
                </div>
            </Centrelized>
        </Wrapper>
    );
}

export default ResendCode;