import React, { useState } from 'react';
import { SimpleForm } from 'react-admin';
import { Loader } from '../pageComponents';
import { CpfInput } from '../CpfInput';
import {
    Centrelized,
    Title,
    ConfirmButton,
} from '..';

const RedeemWhithCpf = ({ title, children, redeemCashbackLessPassword, loading }) => {
    const [cpf, setCpf] = useState("");

    return (
        <div style={{ width: '300px' }}>
            <Centrelized>
                <Title center={true}>{title}</Title>
                <SimpleForm toolbar={null} variant="outlined">
                    <CpfInput
                        onChange={value => setCpf(value)}
                        variant="outlined"
                    />
                    {loading ?
                        <Loader /> :
                        <ConfirmButton onClick={() => redeemCashbackLessPassword(cpf)} />
                    }
                    {children}
                </SimpleForm>
            </Centrelized>
        </div>
    );

}

export default RedeemWhithCpf;