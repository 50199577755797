import React, { useState, createContext, useContext } from "react";
import Register from './Register'

const RegisterContext = createContext();

export const RegisterProvider = () => {
    const [cellphone, setCellphone] = useState();
    const [userId, setUserId] = useState();
    const [cpf, setCpf] = useState();
    const [resendCode, setResendCode] = useState(false);
    const [isNewCustomer, setIsNewCustomer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState('')

    const clearFlow = () => {
        setCellphone(null);
        setResendCode(false);
        setIsNewCustomer(false);
    }

    return (
        <RegisterContext.Provider value={{
            cellphone,
            setCellphone,
            userId,
            setUserId,
            cpf,
            setCpf,
            resendCode,
            setResendCode,
            clearFlow,
            loading,
            setLoading,
            isNewCustomer,
            setIsNewCustomer,
            code,
            setCode
        }}>
            <Register />
        </RegisterContext.Provider>
    );
};

export const useRegisterContext = () => {
    const {
        cellphone,
        setCellphone,
        userId,
        setUserId,
        cpf,
        setCpf,
        resendCode,
        setResendCode,
        clearFlow,
        loading,
        setLoading,
        isNewCustomer,
        setIsNewCustomer,
        code,
        setCode
    } = useContext(RegisterContext);

    return {
        cellphone,
        setCellphone,
        userId,
        setUserId,
        cpf,
        setCpf,
        resendCode,
        setResendCode,
        clearFlow,
        loading,
        setLoading,
        isNewCustomer,
        setIsNewCustomer,
        code,
        setCode
    };
};