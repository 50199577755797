import React from 'react';
import { Wrapper, UserLogin, Centrelized } from '../../components';

export default ({ loginCallback }) => {
    return (
        <Wrapper>
            <Centrelized>
                <UserLogin title="Consultar saldo do cliente" callback={loginCallback} />
            </Centrelized>
        </Wrapper>
    );
}