import { onlyNumbers } from '@brazilian-utils/brazilian-utils';
import { findDOMNode } from 'react-dom';

const triggerPrint = (target) => {
    setTimeout(() => {
        target.contentWindow.focus();
        target.contentWindow.print();
        removeWindow(target);

    }, 500);
}

const removeWindow = (target) => {
    setTimeout(() => {
        target.parentNode.removeChild(target);
    }, 500);
}

export const printElement = (component) => {
    const contentEl = component();

    if (contentEl === undefined) {
        console.error("Refs are not available for stateless components. For 'react-to-print' to work only Class based components can be printed");
        return false;
    }

    let printWindow = document.createElement('iframe');
    //printWindow.setAttribute("id", shortid.generate());
    printWindow.style.position = 'absolute';
    printWindow.style.top = '-1000px';
    printWindow.style.left = '-1000px';

    const contentNodes = findDOMNode(contentEl);
    const linkNodes = document.querySelectorAll('link[rel="stylesheet"]');

    const linkTotal = linkNodes.length || 0;
    let linkLoaded = 0;

    const markLoaded = (type) => {
        linkLoaded++;

        if (linkLoaded === linkTotal) {
            triggerPrint(printWindow);
        }

    };

    printWindow.onload = () => {
        /* IE11 support */
        if (window.navigator && window.navigator.userAgent.indexOf('Trident/7.0') > -1) {
            printWindow.onload = null;
        }

        let domDoc = printWindow.contentDocument || printWindow.contentWindow.document;
        const srcCanvasEls = [...contentNodes.querySelectorAll('canvas')];

        domDoc.open();
        domDoc.write(contentNodes.outerHTML);
        domDoc.close();

        /* remove date/time from top */
        const defaultPageStyle = "@page { size: auto;  margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; } }"

        let styleEl = domDoc.createElement('style');
        styleEl.appendChild(domDoc.createTextNode(defaultPageStyle));
        domDoc.head.appendChild(styleEl);

        const canvasEls = domDoc.querySelectorAll('canvas');
        [...canvasEls].forEach((node, index) => {
            node.getContext('2d').drawImage(srcCanvasEls[index], 0, 0);
        });

        const headEls = document.querySelectorAll('style, link[rel="stylesheet"]');

        [...headEls].forEach((node, index) => {

            let newHeadEl = domDoc.createElement(node.tagName);
            let styleCSS = "";

            if (node.tagName === 'STYLE') {

                if (node.sheet) {
                    for (let i = 0; i < node.sheet.cssRules.length; i++) {
                        styleCSS += node.sheet.cssRules[i].cssText + "\r\n";
                    }

                    newHeadEl.setAttribute('id', `react-to-print-${index}`);
                    newHeadEl.appendChild(domDoc.createTextNode(styleCSS));

                }

            } else {

                let attributes = [...node.attributes];
                attributes.forEach(attr => {
                    newHeadEl.setAttribute(attr.nodeName, attr.nodeValue);
                });

                newHeadEl.onload = markLoaded.bind(null, 'link');
                newHeadEl.onerror = markLoaded.bind(null, 'link');

            }

            domDoc.head.appendChild(newHeadEl);

        });

        if (linkTotal === 0) {
            triggerPrint(printWindow);
        }

    };

    document.body.appendChild(printWindow);
};

export const formatPartiallyHiddenCpf = (num) => {
    if (!num) return num;
    const cpfNumbers = onlyNumbers(num);
    return `***.***.${cpfNumbers.substring(6, 9)}-${cpfNumbers.substring(9, 11)}`
}
