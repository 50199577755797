import React, { createContext, useState, useContext, useRef, useCallback, useEffect } from 'react';
import GiftList from './GiftList';
import LoginGift from './LoginGift';
import { useReactToPrint } from 'react-to-print';
import { Coupon } from '../../components';

const GiftContext = createContext();

export const Gift = () => {
    const [userId, setUserId] = useState();
    const [coupon, setCoupon] = useState();

    const couponRef = useRef(null);

    const reactToPrintContent = useCallback(() => {
        return couponRef.current;
    }, []);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        removeAfterPrint: true,
    });

    const clearFlow = () => {
        setUserId(null);
        setCoupon(null);
    }

    useEffect(() => {
        if (userId && coupon) {
            handlePrint();
            clearFlow();
        }
    }, [coupon, reactToPrintContent, handlePrint, userId]);

    return (
        <GiftContext.Provider
            value={{
                userId,
                setUserId,
                setCoupon, 
                clearFlow
            }}
        >
            <GiftList />
            <LoginGift />
            <div style={{ display: "none" }}>
                <Coupon
                    coupon={coupon}
                    ref={couponRef} />
            </div>
        </GiftContext.Provider>
    );
};

export const useGiftContext = () => {
    const { userId, setUserId, setCoupon, clearFlow } = useContext(GiftContext);
    return { userId, setUserId, setCoupon, clearFlow };
}