import React, { useEffect, useState } from 'react';
import { Wrapper, Title } from '../../components';
import { useGiftContext } from '.';
import { getChainId } from '../../lib';
import {
    useDataProvider,
    Loading,
    useNotify
} from 'react-admin';
import { formatCPF } from '@brazilian-utils/brazilian-utils';
import {
    Grid,
    Typography,
    makeStyles,
} from '@material-ui/core';
import ProductCard from '../ProductCard';

const useStyles = makeStyles({
    root: {
        marginTop: '1em',
    },
    noItems: {
        height: 40,
        marginTop: 40,
        marginBottom: 40
    }
});

const GiftList = () => {
    const [list, setList] = useState();
    const [loading, setLoading] = useState(false);
    const { userId, clearFlow, setCoupon } = useGiftContext();
    const dataProvider = useDataProvider();
    const notify = useNotify()
    const classes = useStyles();

    const generateCoupon = (id) => {
        setLoading(true);
        dataProvider.create(`chains/${getChainId()}/coupons/redeem-gift/${id}`, {})
            .then(({ data }) => {
                const coupon = list.find(c => c.id === data.id);
                setCoupon({ ...coupon, barcode: data.barcode, customer: formatCPF(coupon.__customer__.cpf) })
            })
            .catch((e) => {
                notify(e.status === 502 ? 'Sistema RTI fora de operação' : 'Erro ao resgatar brindes. Tente novamente', 'warning');
             })
            .finally(() => { setLoading(false); });
    };

    useEffect(() => {
        if (userId) {
            setLoading(true);
            dataProvider.getList(`chains/${getChainId()}/customers/${userId}/coupons`, { filter: { isGift: true, redeemed: false } })
                .then(({ data }) => { setList(data); })
                .catch((e) => {
                    notify('Erro ao carregar brindes. Tente novamente', 'warning');
                    clearFlow();
                })
                .finally(() => { setLoading(false); });
        }
    }, [userId, clearFlow, dataProvider, notify]);

    if (!userId) { return null; }

    if (loading) { return <Loading />; }

    return (
        <Wrapper>
            {list && list.length ? (
                <>
                    <Title center={true}>
                        Todos os Brindes
                    </Title>
                    <Grid container spacing={2} className={classes.root}>
                        {list.map(({ product, place: { name }, id }) => (
                            <ProductCard product={{ ...product, place: name, isGift: true }} generateCoupon={() => generateCoupon(id)} />
                        ))}
                    </Grid>
                </>
            ) :
                <Typography
                    className={classes.noItems}
                    align="center"
                >
                    Nenhum brinde encontrado
                </Typography>
            }
        </Wrapper>
    );
};

export default GiftList;
