import React, { useState, useEffect } from 'react';
import { useDataProvider, Button, useNotify } from 'react-admin';
import { getChainId } from '../../lib'
import { Wrapper, Centrelized, Title, Loader, Row } from '../../components'
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import "moment/locale/pt-br";
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Typography, makeStyles, withStyles, Paper } from '@material-ui/core';
import { formatCPF } from '@brazilian-utils/brazilian-utils';

const useStyles = makeStyles({
    table: {
        marginTop: 20
    },
    reprintButton: {
        height: 36,
        textTransform: 'none',
    },
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#E5E5E5',
        fontSize: 16,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const TransactionType = {
    expiration: 'expiration',
    adition: 'adition',
    refuel: 'refuel',
    redemption: 'redemption',
    refund: 'refund'
}

const ClientStatement = ({ userId, printCallback }) => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const [dateFrom, setDateFrom] = useState(moment().subtract(30, 'd').startOf('days'))
    const [dateTo, setDateTo] = useState(moment().endOf('days'))
    const [tableData, setTableData] = useState(null)
    const [balance, setBalance] = useState(null)

    const ReprintButton = ({ coupon, printCallback }) => (
        <Button
            className={classes.reprintButton}
            onClick={() => printCallback(coupon)}
            label="Reimprimir"
            variant="contained"
        />
    )

    const Statement = ({ transactions }) => {
        if (!transactions || transactions.length === 0) {
            return (
                <Typography align="center">
                    Nenhum lançamento encontrado
                </Typography>
            );
        }
        return (
            <TableContainer className={classes.table} component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">Data</StyledTableCell>
                            <StyledTableCell align="center">Pontos</StyledTableCell>
                            <StyledTableCell align="center">Descrição</StyledTableCell>
                            <StyledTableCell align="center">Produto</StyledTableCell>
                            <StyledTableCell align="center">Ponto de Troca</StyledTableCell>
                            <StyledTableCell align="center">Cupom</StyledTableCell>
                            <StyledTableCell align="center"></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(transactions.map(row => {
                            return (
                                <TableRow key={row.id}>
                                    <TableCell align="center">{moment(row.createdAt).format('DD/MM/YYYY HH:mm')}</TableCell>
                                    <TableCell align="center">{row.type === TransactionType.expiration || row.type === TransactionType.redemption ? -row.value : row.value}</TableCell>
                                    <TableCell align="center">{row.typeString}</TableCell>
                                    <TableCell align="center">{row.isCoupon ? row.isCashback ? 'Cashback' : row.product.name : null}</TableCell>
                                    <TableCell align="center">{row.isCoupon && row.place?.name ? row.place.name : null}</TableCell>
                                    <TableCell align="center">{row.barcode}</TableCell>
                                    <TableCell align="center">{
                                        row.isCoupon ?
                                            row.redeemed ? "Cupom Utilizado"
                                                : row.refunded ? "Cupom estornado"
                                                    :
                                                    <ReprintButton coupon={row} printCallback={printCallback} />
                                            : null}
                                    </TableCell>
                                </TableRow>);
                        }))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            let filter = {
                from: moment.utc(dateFrom),
                to: moment.utc(dateTo),
            }

            dataProvider.getList(`chains/${getChainId()}/customers/${userId}/transactions`, { filter })
                .then(response => {
                    let transactions = response.data.transactions;
                    const { currentBalance, customerName } = response.data;

                    for (var index = 0; index < transactions.length; index++) {
                        const t = transactions[index];
                        switch (t.type) {
                            case TransactionType.expiration:
                                t.typeString = "Expiração"
                                break;
                            case TransactionType.adition:
                                t.typeString = "Adição"
                                break;
                            case TransactionType.refuel:
                                t.typeString = "Abastecimento"
                                break;
                            case TransactionType.redemption:
                                t.typeString = "Resgate"
                                break;
                            case TransactionType.refund:
                                t.typeString = "Estorno"
                                break;
                            default: t.typeString = ""
                        }

                        if (t.coupons && t.coupons.length > 0) {
                            // eslint-disable-next-line no-loop-func
                            t.coupons.forEach((c, couponIndex) => {
                                const { value: couponValue } = c;
                                transactions.splice(index, couponIndex === 0 ? 1 : 0, {
                                    ...c,
                                    value: couponValue,
                                    isCoupon: true,
                                    typeString: t.typeString,
                                    type: t.type,
                                    customer: formatCPF(t.customerCpf),
                                    customerName,
                                    place: t.place
                                })
                            });
                        }
                    }

                    setTableData(transactions);
                    setBalance(currentBalance);
                    setLoading(false);
                })
                .catch(error => {
                    notify('Erro ao listar o extrato', 'warning');
                    setLoading(false);
                })
        }
        fetchData()
    }, [dateFrom, dateTo, dataProvider, notify, userId])

    return (
        <Wrapper>
            <Centrelized>
                <Row width='100%'>
                    <MuiPickersUtilsProvider utils={MomentUtils} locale='pt-br'>
                        <DatePicker
                            label="De"
                            value={dateFrom}
                            onChange={date => setDateFrom(date)}
                            format={'DD/MM/YYYY'}
                        />
                        <DatePicker
                            label="Até"
                            value={dateTo}
                            onChange={date => setDateTo(date)}
                            format={'DD/MM/YYYY'}
                        />
                    </MuiPickersUtilsProvider>
                </Row>
                <Title marginTop={true}>Saldo atual: {balance && Number(balance).toFixed(2)} pontos</Title>
                <Title marginTop={true}>Histórico</Title>
                {loading ? <Loader /> : tableData ? <Statement transactions={tableData} /> : null}
            </Centrelized>
        </Wrapper>
    );
}

export default ClientStatement;