import React, { useState } from 'react';
import { SimpleForm, TextInput, useNotify, useDataProvider } from 'react-admin';
import { getChainId } from '../../lib';
import { Loader } from '../pageComponents';
import { CpfInput } from '../CpfInput';
import {
    Centrelized,
    Title,
    ConfirmButton,
} from '../../components';

const UserLogin = ({ title, children, callback }) => {
    const [cpf, setCpf] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const handleLogin = async () => {
        setLoading(true);
        dataProvider.create(`chains/${getChainId()}/customers/login`, { data: { cpf, password } })
            .then((response) => {
                setLoading(false);
                callback(response.id);
            })
            .catch((err) => {
                setLoading(false);
                notify('CPF ou senha inválidos', 'warning');
            })
    }

    return (
        <div style={{ width: '300px' }}>
            <Centrelized>
                <Title center={true}>{title}</Title>
                <SimpleForm toolbar={null} variant="outlined">
                    <CpfInput
                        onChange={value => setCpf(value)}
                        variant="outlined"
                    />
                    <TextInput
                        fullWidth
                        source="password"
                        label="Senha"
                        type="password"
                        onChange={event => setPassword(event.target.value)}
                    />
                    {loading ?
                        <Loader /> :
                        <ConfirmButton onClick={handleLogin} />
                    }
                    {children}
                </SimpleForm>
            </Centrelized>
        </div>
    );

}

export default UserLogin;