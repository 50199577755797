import React, { useState, useEffect } from 'react';
import {
    TextInput,
    SimpleForm,
    useDataProvider,
    Loading,
    Error,
} from 'react-admin';
import { getChainId } from '../lib';
import {
    withStyles,
    makeStyles,
    Button,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    TableContainer,
    Paper
} from '@material-ui/core';
import { Title, CancelButton } from '../components';
import { PLACE_ID_KEY } from '../constants';

const useStyles = makeStyles({
    alignLeft: {
        marginLeft: 16,
    },
    redeeemButton: {
        height: 36,
        backgroundColor: '#7FC99A',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#7FC99A',
        }
    },
    noItems: {
        height: 40,
        marginTop: 40,
        marginBottom: 40
    },
    table: {
        marginTop: 20
    },
    loading: {
        marginTop: 80,
    },
    cancelButton: {
        height: 36,
        width: 40,
        marginBottom: 30,
        backgroundColor: '#C93333',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#C93333',
        }
    },
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#E5E5E5',
        fontSize: 16,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const PlaceList = ({ product, placeSelectedCallback, cancellCallback }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [search, setSearch] = useState();
    const [placesList, setPlacesList] = useState()
    const dataProvider = useDataProvider();

    const onSearch = (event) => {
        setSearch(event.target.value)
    }

    useEffect(() => {
        const makePlacesRequest = async () => {
            const filter = product ? { search, productId: product.id } : { search, acceptsCashback: true };
            const pagination = { page: 1, perPage: 50000 };
            const sort = { field: 'id', order: 'DESC' };
            const placeId = localStorage.getItem(PLACE_ID_KEY);
            if (placeId) {
                filter.id = placeId;
            }
            filter.listActive = true;

            dataProvider.getList(`chains/${getChainId()}/places`, { pagination, sort, filter })
                .then((places) => {
                    setPlacesList(places.data);
                    setLoading(false);
                })
                .catch((err) => {
                    setError(err);
                    setLoading(false);
                });

        }
        makePlacesRequest();
    }, [search, dataProvider, product]);

    if (loading) return <Loading className={classes.loading} />;
    if (error) return <Error />;
    if (!placesList) return null;

    const productInStock = (product, place) => {
        if (product) {
            const productPlace = product.productPlaces.find(pp => pp.placeId === place.id);
            return product.stock ? productPlace.warehouse > 0 || productPlace.showcase > 0 : true;
        } else {
            return true;
        }
    };

    return (
        <>
            <SimpleForm toolbar={null} variant="outlined">
                <CancelButton onClick={cancellCallback} />
                <TextInput
                    source="pesquisar"
                    onChange={onSearch}
                    variant="outlined"
                    style={{ margin: 0 }} />
            </SimpleForm>
            <div className={classes.alignLeft}>
                <Title>
                    Disponível em
                </Title>
                {placesList.length > 0 ?
                    <TableContainer className={classes.table} component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Local</StyledTableCell>
                                    <StyledTableCell>Endereço</StyledTableCell>
                                    {product && <StyledTableCell>Pontos</StyledTableCell>}
                                    <StyledTableCell></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {placesList.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.street}, {row.streetNumber}, {row.city}</TableCell>
                                        {product && <TableCell>{row.productPlaces[0].price}</TableCell>}
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                className={classes.redeeemButton}
                                                onClick={() => placeSelectedCallback(row)}
                                                disabled={!productInStock(product, row)}
                                            >
                                                Resgatar
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Typography
                        className={classes.noItems}
                        align="center"
                    >
                        Nenhum ponto de troca
                    </Typography>
                }
            </div>
        </>
    );
}

export default PlaceList;