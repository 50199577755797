import React, { useState } from 'react';
import {
    Wrapper,
    Title,
    CancelButton,
    CellphoneInput,
    Loader,
    ConfirmButton
} from '../../components'
import { getChainId } from '../../lib';
import { makeStyles } from '@material-ui/core';
import { SimpleForm, useDataProvider, useNotify, required } from 'react-admin';
import { useRegisterContext } from '.'

const useStyles = makeStyles({
    centered: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 16,
        flexDirection: 'column',
    },

    cellphoneInput: {
        width: 250,
        marginTop: 60,
    }
});

const RegisterCellphone = () => {
    const { setCellphone, loading, setLoading, cpf, clearFlow } = useRegisterContext();
    const [newCellphone, setNewCellphone] = useState();
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const handleConfirm = () => {
        setLoading(true);

        dataProvider.create(`chains/${getChainId()}/customers/signup`, { data: { cpf, cellphone: newCellphone } })
            .then((response) => {
                notify(`Código enviado.`);
                setCellphone(response.data.cellphone);
            })
            .catch((err) => {
                notify('Erro ao enviar código!', 'warning');
            })
            .finally(() => { setLoading(false); })
    }

    return (
        <Wrapper>
            <Title center={true}>Cliente não encontrado. Informe o celular para se registrar.</Title>
            <div className={classes.centered}>
                <SimpleForm toolbar={null} variant="outlined" submitOnEnter={false}>
                    <div className={classes.cellphoneInput}>
                        <CellphoneInput
                            onChange={value => setNewCellphone(value)}
                            variant="outlined"
                            validate={[required()]}
                        />
                    </div>
                    {loading ?
                        <Loader /> :
                        [
                            <ConfirmButton onClick={handleConfirm} />,
                            <CancelButton fullWidth onClick={clearFlow} />
                        ]
                    }
                </SimpleForm>
            </div>
        </Wrapper>
    );
}

export default RegisterCellphone;