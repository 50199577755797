import PropTypes from 'prop-types';
import React from 'react';
import { TextInput } from 'react-admin';

export const CellphoneInput = ({ source, label, onChange, variant }) =>
    <TextInput
        fullWidth
        source={source}
        label={label}
        variant={variant}
        onChange={(event) => {
            onChange(event.target.value.replace(/[^\d]/g, ''))
        }}
        format={(value) => value ?
            value.replace(/(\d{2})(\d)/, "($1) $2")
            :
            value
        }
        parse={(value) => value.replace(/[^\d]/g, '')}
    />;

CellphoneInput.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    source: PropTypes.string.isRequired,
};

CellphoneInput.defaultProps = {
    source: "cellphone",
    label: "Celular"
};