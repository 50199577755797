import React from 'react';
import { CpfInput } from '../../components/CpfInput';
import { getChainId } from '../../lib';
import {
    Wrapper,
    Title,
    Loader,
    ConfirmButton,
} from '../../components'
import { SimpleForm, useDataProvider, useNotify, TextInput, required } from 'react-admin';
import { makeStyles, } from '@material-ui/core';
import { useRegisterContext } from '.'

const useStyles = makeStyles({
    centered: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 16,
        flexDirection: 'column',
    },
    cpfInput: {
        width: 250,
        marginTop: 60,
    },
});

const RegisterCpf = () => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const { loading, setLoading, setCpf, setCellphone, setUserId, cpf, setIsNewCustomer } = useRegisterContext()

    const handleConfirm = () => {
        setLoading(true);

        dataProvider.create(`chains/${getChainId()}/customers/forgot-password`, { data: { cpf, recoveryMode: 'cellphone' } })
            .then((response) => {
                notify(`Código enviado.`);
                setCellphone(response.data.cellphone);
                setUserId(response.data.id);
            })
            .catch((err) => {
                if (err.status === 404) { setIsNewCustomer(true); }
                else { notify('Erro ao enviar código!', 'warning'); }
            })
            .finally(() => { setLoading(false); })
    }

    return (
        <Wrapper>
            <Title center={true}>Informe o CPF para resgatar a senha</Title>

            <div className={classes.centered}>
                <SimpleForm toolbar={null} variant="outlined" submitOnEnter={false}>
                    <div className={classes.cpfInput}>
                        <CpfInput
                            onChange={value => setCpf(value)}
                            variant="outlined"
                            validate={[required()]}
                        />
                        <div style={{ display: "none" }}><TextInput /></div>
                    </div>
                    {loading ?
                        <Loader /> :
                        <ConfirmButton onClick={handleConfirm} />
                    }
                </SimpleForm>
            </div>
        </Wrapper>
    );
}

export default RegisterCpf;