import React from 'react'
import { TextInput } from 'react-admin'

const PriceInput: React.FC<{
    source: string,
    label: string,
    precision?: number,
    style?: React.CSSProperties,
    onChange: (value) => {}
}> = ({ source, label, precision = 2, style, onChange = () => { } }) => {
    return (
        <TextInput
            style={style}
            source={source}
            label={label}
            format={(value) => {
                if (!value || value === 0) {
                    if (onChange) {
                        onChange(Number(0).toFixed(precision))
                    }
                    return "R$ 0," + "0".repeat(precision);
                }
                if (onChange) {
                    onChange(Number(value).toFixed(precision))
                }
                return `R$ ${Number(value).toFixed(precision)}`;
            }}
            parse={(value) => {
                if (!value || value === '') {
                    return 0;
                }
                const onlyNumbers = +value.replace(/[^\d]/g, '');
                return onlyNumbers / Math.pow(10, precision);
            }}
        />
    );
}

export default PriceInput;
