import * as React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink } from 'react-admin';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import RedeemIcon from '@material-ui/icons/Redeem';

const Menu = ({ onMenuClick, logout }) => {
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    return (
        <div>
            <MenuItemLink
                to="/statement"
                primaryText="Extrato"
                leftIcon={<ListAltIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/redeem"
                primaryText="Resgate"
                leftIcon={<AccountBalanceWalletOutlinedIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/register"
                primaryText="Cadastro"
                leftIcon={<AssignmentIndOutlinedIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                to="/gift"
                primaryText="Brindes"
                leftIcon={<RedeemIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            {isXSmall && logout}
        </div>
    );
}

export default Menu;