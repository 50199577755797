import {
    Wrapper,
    UserLogin,
    Title,
    Centrelized,
    CancelButton,
    ConfirmButton,
    PriceInput,
    RedeemWhithCpf
} from '../components'
import React, { useState, useRef, useEffect } from 'react';
import ProductList from './ProductList';
import PlaceList from './PlaceList';
import ProductCard from './ProductCard'
import { makeStyles, Button, Dialog, DialogContent } from '@material-ui/core';
import { getChainId, getCashbackFactor, getUserId } from '../lib';
import Coupon from '../components/Coupon'
import { formatCPF } from '@brazilian-utils/brazilian-utils';
import { useDataProvider, useNotify, Loading, useAuthenticated, SimpleForm, NumberInput, FormDataConsumer, usePermissions } from 'react-admin';
import { printElement } from '../utils/utils';
import { PERMISSIONS } from '../constants';

const useStyles = makeStyles({
    centered: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 16,
        flexDirection: 'column',
    },
    placeDetails: {
        margin: 40,
    },
    backButton: {
        marginTop: 20,
    },
    loading: {
        marginTop: 40,
    },
});

const Redeem = () => {
    useAuthenticated();
    const classes = useStyles();
    const [coupons, setCoupons] = useState();
    const [product, setProduct] = useState();
    const [units, setUnits] = useState(1);
    const [points, setPoints] = useState(0);
    const [moneyValue, setMoneyValue] = useState(0);
    const [isDialogOpen, setIsDialogOpen] = useState(true);
    const [place, setPlace] = useState();
    const [loading, setLoading] = useState();
    const [isCashback, setIsCashback] = useState(false);
    const [customerBalance, setCustomerBalance] = useState();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const couponsRef = useRef([]);
    const cashbackFactor = getCashbackFactor();
    const {loading:loadingPermissions, permissions} = usePermissions();
    const employeeId = getUserId();


    useEffect(() => {
        if (coupons && coupons.length === couponsRef.current.length) {
            couponsRef.current.forEach(coupon => {
                printElement(() => coupon);
            });
        }
        setCoupons(null);
        couponsRef.current = [];
    }, [couponsRef.current.length, coupons]);

    const productSelectedCallback = (product) => setProduct(product)
    const placeSelectedCallback = (place) => setPlace(place)
    const cashbackSelectedCallback = (isCashback) => setIsCashback(isCashback);

    const userLoginCallback = (userId) => {
        if (product) {
            redeemPoints(userId);
        } else if (isCashback) {
            redeemCashback(userId);
        }
    }

    const clearFlow = () => {
        setCustomerBalance(null)
        setProduct(null)
        setPlace(null)
        setIsCashback(false)
        setUnits(1);
        setMoneyValue(0);
        setIsDialogOpen(true);
    }

    const onCashbackInput = (value) => {
        setMoneyValue(value);

        if (!value || value === '') {
            setPoints(0);
        }
        const onlyNumbers = +value.replace(/[^\d]/g, '');
        setPoints(+((onlyNumbers / Math.pow(10, 2) / cashbackFactor).toFixed(2)));
    }

    const redeemCashback = (userId) => {
        setLoading(true);
        dataProvider.create(`chains/${getChainId()}/customers/${userId}/redeem-cashback`, { data: { value: points, placeId: place.id } })
            .then((response) => {
                clearFlow();
                setLoading(false);
                notify('Transação efetuada! Imprimindo comprovante...');

                let coupons = [];
                const c = {
                    ...response.data,
                    customer: formatCPF(response.data.transaction.customerCpf),
                    product: {
                        name: response.data.partnerValue.toFixed(2),
                        description: 'Cashback',
                    },
                    isCashback: true,
                }

                coupons.push(c);
                setCoupons(coupons);

            })
            .catch((err) => {
                if (err.body && err.body.attributes && err.body.attributes.data && err.body.attributes.data.balance) {
                    setCustomerBalance(err.body.attributes.data.balance);
                    setLoading(false);
                } else {
                    clearFlow();
                    setLoading(false);
                    notify(`Erro! ${err.body.message}`, 'warning');
                }
            })
    }

    const redeemPoints = (userId) => {
        setLoading(true);
        dataProvider.create(`chains/${getChainId()}/customers/${userId}/transactions`, { data: { productId: product.id, placeId: place.id, origin: 'resgate', units } })
            .then((response) => {
                clearFlow();
                setLoading(false);
                notify('Produto resgatado! Imprimindo comprovante...');

                const coupons = response.data.coupons;
                coupons.forEach(c => {
                    c.customerName = response.data.customerName;
                    c.customer = formatCPF(response.data.customerCpf);
                });

                setCoupons(coupons);
            })
            .catch((err) => {
                if (err.body && err.body.attributes && err.body.attributes.data && err.body.attributes.data.balance) {
                    setCustomerBalance(err.body.attributes.data.balance);
                    setLoading(false);
                } else {
                    clearFlow();
                    setLoading(false);
                    notify(`Erro! ${err.body.message}`, 'warning');
                }
            })
    }

    const redeemCashbackLessPassword = async (cpf) => {
        setLoading(true);
        dataProvider.create(`chains/${getChainId()}/customers/password-less-redemption`, { data: { cpf, productId: product.id, placeId: place.id, origin: 'resgate', units, employeeId } })
            .then((response) => {
                clearFlow();
                setLoading(false);
                notify('Produto resgatado! Imprimindo comprovante...');

                const coupons = response.data.coupons;
                coupons.forEach(c => {
                    c.customerName = response.data.customerName;
                    c.customer = formatCPF(response.data.customerCpf);
                });

                setCoupons(coupons);
            })
            .catch((err) => {
                if (err.body && err.body.attributes && err.body.attributes.data && err.body.attributes.data.balance) {
                    setCustomerBalance(err.body.attributes.data.balance);
                    setLoading(false);
                } else {
                    clearFlow();
                    setLoading(false);
                    notify(`Erro! ${err.body.message}`, 'warning');
                }
            })
    }

    if (customerBalance) {
        return (
            <Wrapper>
                <Centrelized>
                    <Title center={true}>Não foi possível resgatar o produto!</Title>
                    <Title center={true} marginTop={true}>Saldo do cliente: {customerBalance.toFixed(2)} pontos</Title>
                    <Title center={true}>{!isCashback && `Pontos necessários: ${(place.productPlaces[0].price * units).toFixed(2)} pontos`}</Title>
                    <Title center={true}>{!isCashback && `Pontos que faltam: ${(place.productPlaces[0].price * units - customerBalance).toFixed(2)} pontos`}</Title>

                    <Button
                        className={classes.backButton}
                        onClick={clearFlow}
                        variant="contained">Voltar</Button>
                </Centrelized>
            </Wrapper>
        );
    }

    if (loading || loadingPermissions) {
        return (
            <Wrapper>
                <Loading className={classes.loading} />
            </Wrapper>
        );
    }

    if (isCashback && place) {
        return (
            <Wrapper>
                <Dialog
                    open={isDialogOpen}
                    // onClose={() => {
                    //     setIsDialogOpen(false);
                    // }}
                    >
                    <DialogContent>
                        <SimpleForm toolbar={null} variant='outlined'>
                            <PriceInput
                                label='Cashback'
                                source='cashback'
                                onChange={onCashbackInput}
                                min={0}
                            />
                            <Title>{`${points} pontos`}</Title>

                            <FormDataConsumer>
                                {({ formData: { cashback } }) =>
                                    <ConfirmButton
                                        onClick={() => {
                                            if (cashback) {
                                                setPoints(points);
                                                setIsDialogOpen(false);
                                            }
                                        }}
                                    />}
                            </FormDataConsumer>
                        </SimpleForm>
                    </DialogContent>
                </Dialog>
                <div className={classes.centered}>
                    <div className={classes.placeDetails}>
                        <div className={classes.centered}>
                            <Title>{points || 0} ponto(s)</Title>
                            <Title>{`R$ ${moneyValue}`}</Title>
                        </div>
                    </div>
                    <UserLogin title="" callback={userLoginCallback}>
                        <CancelButton fullWidth onClick={clearFlow} />
                    </UserLogin>
                </div>
            </Wrapper>
        );
    }

    if (product && place) {
        return (
            <Wrapper>
                <Dialog open={isDialogOpen}>
                    <DialogContent>
                        <SimpleForm toolbar={null} variant='outlined'>
                            <NumberInput
                                label='Unidades'
                                source='units'
                                min={1}
                                initialValue={1}
                            />
                            <FormDataConsumer>
                                {({ formData: { units } }) =>
                                    <ConfirmButton
                                        disabled={points === 0 && isCashback === true}
                                        onClick={() => {
                                            setUnits(units);
                                            setIsDialogOpen(false);
                                        }}
                                    />}
                            </FormDataConsumer>
                        </SimpleForm>
                    </DialogContent>
                </Dialog>
                <div className={classes.centered}>
                    <ProductCard product={product} />
                    <div className={classes.placeDetails}>
                        <div className={classes.centered}>
                            <Title>{place.name}</Title>
                            <Title>{units} unidade(s)</Title>
                            <Title>{place.productPlaces[0].price * units} pontos</Title>
                        </div>
                    </div>
                    { permissions.includes(PERMISSIONS.PASSWORD_LESS_REDEMPTIOM) ? 
                    <RedeemWhithCpf loading={loading} redeemCashbackLessPassword={redeemCashbackLessPassword}>
                         <CancelButton fullWidth onClick={clearFlow} />
                    </RedeemWhithCpf>
                    : 
                    <UserLogin title="" callback={userLoginCallback}>
                        <CancelButton fullWidth onClick={clearFlow} />
                    </UserLogin>
                    }
                </div>
            </Wrapper>
        );
    }

    if (product || isCashback) {
        return (
            <Wrapper>
                <div className={classes.centered}>
                    {product && <ProductCard product={product} />}
                </div>
                <PlaceList
                    product={product}
                    placeSelectedCallback={placeSelectedCallback}
                    cancellCallback={clearFlow}
                />
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <ProductList productSelectedCallback={productSelectedCallback} cashbackSelectedCallback={cashbackSelectedCallback} />
            <div style={{ display: "none" }}>
                {coupons && coupons.map(c =>
                    <Coupon
                        coupon={c}
                        ref={el => couponsRef.current.push(el)}
                    />)}
            </div>
        </Wrapper>
    );
}

export default Redeem;