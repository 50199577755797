import React, { useState, useEffect } from 'react';
import {
    Wrapper,
    Title,
    ConfirmButton,
    CancelButton,
    Loader,
    Centrelized
} from '../../components'
import { makeStyles, Button } from '@material-ui/core';
import { SimpleForm, TextInput, useDataProvider, useNotify, required, minLength } from 'react-admin';
import { useRegisterContext } from '.';
import { getChainId } from '../../lib';

const useStyles = makeStyles({
    centered: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 30,
        flexDirection: 'column',
        width: 250,
    },
    resendButton: {
        height: 36,
        textTransform: 'none',
        backgroundColor: '#b7d5ac',
        '&:hover': {
            backgroundColor: '#b7d5ac',
        }
    },
    counter: {
        width: 35,
        height: 35,
        borderRadius: '50%',
        backgroundColor: 'white',
        boxShadow: '6px 6px 12px 0 rgba(0,0,0,0.23)',
        position: 'absolute',
        right: -15,
        top: -15,
        zIndex: '1',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#FCA2A2',
    },
    resendWrapper: {
        position: 'relative',
        margin: '15px 0',
    }

});

const RegisterCode = () => {

    const classes = useStyles();
    const [password, setPassword] = useState('')
    const [disabled, setDisabled] = useState(true);
    const [counter, setCounter] = useState(true);
    const [seconds, setSeconds] = useState(60);
    const { setLoading, loading, userId, code, setCode, clearFlow, setResendCode, cellphone, isNewCustomer, cpf } = useRegisterContext()
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const password2Validation = (value, allValues) => {
        if (!value) {
            return 'Senha obrigatória';
        }
        if (value !== allValues.password) {
            return 'Senhas não conferem';
        }
        return undefined;
    };

    const passwordValidation = (value, allValues) => {
        if (!value) {
            return 'Senha obrigatória';
        }
        if (value !== allValues.password2) {
            return 'Senhas não conferem';
        }
        return undefined;
    };

    const handleConfirm = () => {
        setLoading(true);

        let url = `customers/${userId}/recover-password`;
        let data = { newPassword: password, code }

        if (isNewCustomer) {
            url = `chains/${getChainId()}/customers`;
            data = { cpf, code, password }
        }

        dataProvider.create(url, { data })
            .then(() => {
                if (isNewCustomer) {
                    notify('Cliente cadastrado com sucesso');
                } else {
                    notify(`Senha alterada com sucesso.`);
                }

                clearFlow();
            })
            .catch((err) => {
                notify(err.message || `Erro ao salvar ${isNewCustomer ? '' : 'nova'} senha!`, 'warning');
            })
            .finally(() => { setLoading(false); })
    }

    const handleResend = () => {
        setDisabled(true);
        setResendCode(true);
    }

    const ResendButton = () => (
        <div className={classes.resendWrapper}>
            <div style={counter ? { display: 'flex' } : { display: 'none' }} className={classes.counter}>
                <p>{seconds}s</p>
            </div>
            <Button
                variant="contained"
                className={classes.resendButton}
                onClick={handleResend}
                fullWidth
                disabled={disabled}
            >
                Reenviar código
            </Button>
        </div>
    )

    useEffect(() => {
        const countDown = () => {
            setTimeout(() => {
                if (seconds > 1) {
                    setSeconds(seconds - 1);
                } else {
                    setCounter(false);
                    setDisabled(false);
                }
            }, 1000);
        }
        countDown();
    }, [seconds])

    return (
        <Wrapper>
            <Centrelized>
                <Title center={true}>Digite o código recebido no celular com final {cellphone}</Title>
                <div className={classes.centered}>
                    <SimpleForm toolbar={null} variant="outlined" className={classes.centered}>
                        <TextInput
                            onChange={event => setCode(event.target.value)}
                            variant="outlined"
                            label="Código"
                            source="code"
                            validate={[required()]}
                        />
                        <TextInput
                            onChange={event => setPassword(event.target.value)}
                            variant="outlined"
                            label="Senha"
                            source="password"
                            type="password"
                            validate={[required(), minLength(6), passwordValidation]}
                        />
                        <TextInput
                            variant="outlined"
                            label="Confirmar Senha"
                            source="password2"
                            type="password"
                            validate={[required(), minLength(6), password2Validation]}
                        />
                        {loading ?
                            <Loader /> :
                            <ConfirmButton onClick={handleConfirm} />
                        }
                        <ResendButton />
                        <CancelButton fullWidth onClick={clearFlow} />
                    </SimpleForm>
                </div>
            </Centrelized>
        </Wrapper>
    );
}

export default RegisterCode;