import React, { useState, useEffect } from 'react';
import {
    TextInput,
    SimpleForm,
    useDataProvider,
    Loading,
    Error,
} from 'react-admin';
import { getCashbackFactor, getChainId } from '../lib';
import { makeStyles, Switch, FormControlLabel, Button } from '@material-ui/core';
import ProductGrid from './ProductGrid'
import { Title } from '../components';
import { PLACE_ID_KEY } from '../constants';

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItens: 'center',
        height: 40,
    },
    moneyProductsButton: {
        height: 40,
        color: '#7FC99A',
        textTransform: 'none',
        alignSelf: 'center'
    },
    cashbackButton: {
        marginBottom: 15,
        marginTop: 15,
        height: 36,
        backgroundColor: '#7FC99A',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#7FC99A',
        }
    },

});

const ProductList = ({ productSelectedCallback, cashbackSelectedCallback }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [search, setSearch] = useState();
    const [onlyMoneyProducts, setOnlyMoneyProducts] = useState(false);
    const [productsList, setProductsList] = useState();
    const [featuredProductsList, setFeaturedProductsList] = useState();
    const dataProvider = useDataProvider();
    const cashbackFactor = getCashbackFactor();

    const onSearch = (event) => {
        setSearch(event.target.value)
    }

    const onOnlyMoneyProductsClick = (event) => {
        setOnlyMoneyProducts(event.target.checked);
    }

    useEffect(() => {
        const makeProductsRequests = async () => {
            const filter = { search };
            filter.isCash = onlyMoneyProducts;
            const placeId = localStorage.getItem(PLACE_ID_KEY);
            if (placeId) {
                filter.placeId = placeId;
            }
            filter.listActive = true;

            const pagination = { page: 1, perPage: 50000 };
            const sort = { field: 'name', order: 'ASC' };

            Promise.all([
                dataProvider.getList(`chains/${getChainId()}/featured-products`, { pagination, sort, filter }),
                dataProvider.getList(`chains/${getChainId()}/products`, { pagination, sort, filter }),
            ])
                .then(function ([featuredProducts, products]) {
                    setFeaturedProductsList(featuredProducts.data.map((featuredProduct) => featuredProduct.product));
                    if (onlyMoneyProducts) {
                        setProductsList(products.data.map(moneyProduct => ({
                            ...moneyProduct,
                            name: Number(moneyProduct.name.replace(',', '.') || '0')
                        })).sort((a, b) => {
                            if (a.name > b.name) { return 1; }
                            if (a.name < b.name) { return -1; }
                            return 0;
                        }))
                    } else { setProductsList(products.data); }
                    setLoading(false);
                })
                .catch(function (err) {
                    setError(err);
                    setLoading(false);
                })
        }
        makeProductsRequests();
    }, [search, onlyMoneyProducts, dataProvider]);

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!productsList && !featuredProductsList) return null;

    return (
        <>
            <SimpleForm toolbar={null} variant="outlined">
                <div className={classes.toolbar}>
                    <TextInput
                        source="pesquisar"
                        onChange={onSearch}
                        variant="outlined"
                        style={{ margin: 0 }} />
                    <FormControlLabel
                        label="Produtos em Dinheiro"
                        control={
                            <Switch
                                className={classes.moneyProductsButton}
                                onChange={onOnlyMoneyProductsClick}
                                checked={onlyMoneyProducts} />
                        } />
                </div>
                {cashbackFactor && cashbackFactor > 0 &&
                    <Button
                        variant="contained"
                        onClick={() => cashbackSelectedCallback(true)}
                        className={classes.cashbackButton}>
                        Resgatar Cashback
                    </Button>
                }
            </SimpleForm>
            <Title center={true}>
                Produtos em Destaque
            </Title>
            <ProductGrid data={featuredProductsList} productSelectedCallback={productSelectedCallback} />
            <Title center={true} marginTop={true}>
                Todos os Produtos
            </Title>
            <ProductGrid data={productsList} productSelectedCallback={productSelectedCallback} />
        </>
    );
}

export default ProductList;