import { CHAIN_ID_KEY, CHAIN_NAME_KEY, PERMISSIONS_KEY, PERMISSIONS, CASHBACK_FACTOR, PLACE_ID_KEY, USER_ID_KEY } from '../constants';
import { apiUrl } from '../providers/apiProvider';

const authProvider = {
  login: params => {
    const { username, password } = params;
    const request = new Request(`${apiUrl}/employees/login`, {
      method: 'POST',
      body: JSON.stringify({ login: username.trim(), password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }

        return response.json();
      })
      .then(({ authorization, permissions, chainId, chainName, cashbackFactor, placeId, id }) => {
        if (!permissions || !permissions.includes(PERMISSIONS.ACCESS_RESGATE)) {
          throw new Error("Usuário não tem permissão para acessar o módulo Resgate");
        }
        localStorage.setItem('authorization', authorization);
        localStorage.setItem(PERMISSIONS_KEY, JSON.stringify(permissions));
        localStorage.setItem(CHAIN_ID_KEY, chainId);
        localStorage.setItem(CHAIN_NAME_KEY, chainName);
        if (placeId) {
          localStorage.setItem(PLACE_ID_KEY, placeId);
        }
        localStorage.setItem(CASHBACK_FACTOR, cashbackFactor);
        localStorage.setItem(USER_ID_KEY, id);
      });
  },
  logout: params => {
    localStorage.removeItem('authorization');
    localStorage.removeItem(PERMISSIONS_KEY);
    localStorage.removeItem(CHAIN_ID_KEY);
    localStorage.removeItem(CHAIN_NAME_KEY);
    localStorage.removeItem(PLACE_ID_KEY);
    localStorage.removeItem(CASHBACK_FACTOR);
    localStorage.removeItem(USER_ID_KEY);
    return Promise.resolve();
  },
  checkAuth: params => {
    return localStorage.getItem('authorization')
      ? Promise.resolve()
      : Promise.reject();
  },
  checkError: error => {
    const { status, body } = error;
    if (status === 401 || status === 403) {
      if (body && body.attributes && body.attributes.data && body.attributes.data.type === 'customer') {
        return Promise.resolve();
      } else {
        localStorage.removeItem('authorization');
        return Promise.reject();
      }
    }
    return Promise.resolve();
  },
  getPermissions: params => {
    const permissions = JSON.parse(localStorage.getItem(PERMISSIONS_KEY) || '');
    if (permissions && permissions.includes(PERMISSIONS.ACCESS_RESGATE)) {
      return Promise.resolve(permissions);
    }

    return Promise.reject();
  },
};
export default authProvider;