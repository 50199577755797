import React from 'react';
import { Route } from 'react-router-dom';

import {
    Query,
    Register,
    Statement,
    Gift
} from './containers';

export default [
    <Route exact path='/statement' component={Statement} />,
    <Route exact path='/redeem' component={Query} />,
    <Route exact path='/register' component={Register} />,
    <Route exact path='/gift' component={Gift} />,
]