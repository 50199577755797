import React from 'react';
import { Wrapper, UserLogin, Centrelized } from '../../components';
import { useGiftContext } from '.';

const LoginGift = () => {
    const { setUserId, userId } = useGiftContext();

    if (userId) { return null; }

    return (
        <Wrapper>
            <Centrelized>
                <UserLogin title="Consultar brindes do cliente" callback={setUserId} />
            </Centrelized>
        </Wrapper>
    );
};

export default LoginGift;
