import React from 'react';
import RegisterCpf from './RegisterCpf';
import RegisterCode from './RegisterCode';
import ResendCode from './ResendCode';
import RegisterCellphone from './RegisterCellphone';
import { useAuthenticated } from 'react-admin';
import { useRegisterContext } from '.'

const Register = () => {
    useAuthenticated();
    const { resendCode, cellphone, isNewCustomer } = useRegisterContext()

    if (resendCode) { return <ResendCode /> }

    if (cellphone) { return <RegisterCode /> }

    if (isNewCustomer) { return <RegisterCellphone /> }

    return <RegisterCpf />
}

export default Register;
