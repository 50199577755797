import React from 'react';
import ProductCard from './ProductCard';
import {
    Grid,
    Typography,
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        marginTop: '1em',
    },
    noItems: {
        height: 40,
        marginTop: 40,
        marginBottom: 40
    }
});

export default ({ data, productSelectedCallback }) => {
    const classes = useStyles();

    return data && data.length > 0 ? (
        <Grid container spacing={2} className={classes.root}>
            {data.map(product => (
                <ProductCard product={product} productSelectedCallback={productSelectedCallback} />
            ))}
        </Grid>
    ) :
        <Typography
            className={classes.noItems}
            align="center"
        >
            Nenhum produto encontrado
        </Typography>;
};