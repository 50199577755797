import PropTypes from 'prop-types';
import React from 'react';
import { TextInput } from 'react-admin';
import { formatCPF, isValidCPF } from '@brazilian-utils/brazilian-utils';

export const CpfInput = ({ source, label, onChange, variant }) =>
    <TextInput
        fullWidth
        source={source}
        label={label}
        variant={variant}
        onChange={(event) => {
            onChange(event.target.value.replace(/[^\d]/g, ''))
        }}
        format={(value) => formatCPF(value)}
        parse={(value) => value.replace(/[^\d]/g, '')}
        validate={ value => value && isValidCPF(value) ? undefined : "CPF inválido" }
    />;

CpfInput.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    source: PropTypes.string.isRequired,
};

CpfInput.defaultProps = {
    source: "cpf",
    label: "CPF"
};