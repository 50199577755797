import React, { useState, useCallback, useRef, useEffect } from 'react';
import ClientStatement from './ClientStatement';
import { default as LoginStatement } from './LoginStatement';
import Coupon from '../../components/Coupon'
import { useReactToPrint } from 'react-to-print';
import { Wrapper, OperatorManagerLogin, Centrelized } from '../../components';
import { useAuthenticated, usePermissions } from 'react-admin';
import { PERMISSIONS } from '../../constants';

const Statement = () => {
    useAuthenticated();
    const [userId, setUserId] = useState(null);
    const [coupon, setCoupon] = useState(null);
    const [insufficientScope, setInsufficientScope] = useState(false);
    const { permissions } = usePermissions(); 

    const couponRef = useRef(null);
    const reactToPrintContent = useCallback(() => {
        return couponRef.current;
    }, []);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        removeAfterPrint: true,
    });

    useEffect(() => {
        if (coupon && !insufficientScope) {
            handlePrint();
            setCoupon(null);
        }
    }, [coupon, reactToPrintContent, handlePrint, insufficientScope]);

    const handleReprintClick = (coupon) => {
        if (!permissions.includes(PERMISSIONS.REPRINT_COUPON)) {
            setInsufficientScope(true);
        }
        setCoupon(coupon);
    }

    const employeeLoginCallback = (response) => {
        if (response.permissions.includes(PERMISSIONS.REPRINT_COUPON)) {
            setInsufficientScope(false);
        }
    }

    if (insufficientScope) {
        return (
            <Wrapper>
                <Centrelized>
                    <OperatorManagerLogin loginCallback={employeeLoginCallback} />
                </Centrelized>
            </Wrapper>
        );
    }

    if (userId) {
        return (
            <>
                <ClientStatement userId={userId} printCallback={handleReprintClick} />
                <div style={{ display: "none" }}>
                    <Coupon
                        coupon={coupon}
                        ref={couponRef} />
                </div>
            </>
        );
    }

    return <LoginStatement loginCallback={setUserId} />;
}
export default Statement;